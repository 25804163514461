h1.index_pageTitle_3R7O- {
  font: normal normal 500 24px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  margin-bottom: 21px;
}
h2.index_cardTitle_pUJXC, .card > .card-header > h2 {
  padding: 10px 20px;
  font: normal normal 500 18px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  border-left: 4px solid #5068C2;
}
.ant-btn {
  font: normal normal 500 14px/30px "Noto Sans JP", sans-serif;
}
.ant-btn.index_filledBtn_thavt {
    background: #5068C2 0% 0% no-repeat padding-box !important;
    border-style: none !important;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font: normal normal 500 14px/30px "Noto Sans JP", sans-serif !important;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    padding: 0 18px;
}
.ant-btn.index_filledBtn_thavt span {
      color: #FFFFFF !important;
}
.ant-btn.index_filledBtn_thavt:hover {
      opacity: 0.2;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.index_filledBtn_thavt) {
    color: #5068C2 !important;
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #D8D8DA;
    border-radius: 4px;
    opacity: 1;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.index_filledBtn_thavt):hover {
      background: #EFEFF7 0% 0% no-repeat padding-box;
      border: 1px solid #5068C2 !important;
      border-radius: 4px;
      opacity: 1;
}
.ant-btn:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f2f4f8 !important;
    box-shadow: none !important;
    pointer-events: none;
}
.ant-popover-buttons button {
  line-height: 24px !important;
}
.ant-pagination-item {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D8D8DA;
  border-radius: 4px;
}
.ant-pagination-item:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #5068C2;
    border-radius: 4px;
}
.ant-pagination-item-active {
  background: #EFEFF7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #5068C2;
}
.ant-list-pagination {
  text-align: center;
}
.ant-pagination {
  text-align: center !important;
}
.ant-list-item:hover {
  background: #EFEFF7 0% 0% no-repeat padding-box;
}
.ant-list-item .ant-list-item-action-split {
  width: 0;
}
.card > .card-header {
  padding-left: 0 !important;
}
.card .card-body {
  padding: 16px !important;
}
@media screen and (max-width: 767px) {
.card .card-body {
      padding-left: 12px !important;
}
}
.ant-radio-group :not(.ant-radio-checked) span.ant-radio-inner {
  border-color: #BBBBBB !important;
}
.ant-radio-group :not(.ant-radio-checked) span.ant-radio-inner::after {
    border-color: #BBBBBB !important;
}
.ant-radio-group .ant-radio-checked span.ant-radio-inner {
  border-color: #5068C2 !important;
}
.ant-radio-group .ant-radio-checked span.ant-radio-inner::after {
    background-color: #5068C2 !important;
    border-color: #5068C2 !important;
}
.ant-tabs-nav {
  background: #FFF 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  color: #3042A0;
  font: normal normal 500 14px/20px "Noto Sans JP", sans-serif;
}
.ant-tabs-nav .ant-tabs-tab:hover {
    color: #3042A0;
    background: #EFEFF7 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
}
.ant-tabs-nav .ant-tabs-tab-active {
    background: #3042A0 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
    color: #FFF;
}
.ant-tabs-nav .ant-tabs-tab-active:hover {
      background: #3042A0 0% 0% no-repeat padding-box;
      color: #FFF;
}
.ant-tabs-nav .ant-tabs-ink-bar {
    background-color: #3042A0 !important;
}
.ant-checkbox-group .ant-checkbox-wrapper {
  font: normal normal normal 14px/33px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
}
.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox {
    border: 1px solid #BBBBBB;
    border-radius: 2px;
}
.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5068C2;
    border-color: #5068C2;
}
.ant-form .ant-form-item > .ant-form-item-label > label {
  font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #787B87;
}
span.index_requiredMark_1-Ig5 {
  display: inline-block;
  width: 36px;
  height: 20px;
  background: #EF5350 0% 0% no-repeat padding-box;
  border-radius: 2px;
  color: #fff;
  font: normal normal normal 11px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  margin: 8px;
  padding: 1px 6px;
}
.ant-table-wrapper table {
  table-layout: fixed;
  word-break: break-all;
  border-collapse: separate;
  border-radius: 4px;
  border: none !important;
}
.ant-table-wrapper table th {
    border: none !important;
}
.ant-table-wrapper tr {
  font: normal normal 300 14px/14px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
}
.ant-table-wrapper tr.index_stripe0_1gsTd {
    background: #FAFBFC 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe0_1gsTd td.ant-table-column-sort {
      background: #FAFBFC 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe1_2WxpU {
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe1_2WxpU td.ant-table-column-sort {
      background: #FFFFFF 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr td {
    border: none;
}
.ant-table-wrapper .ant-table-header-column .ant-table-column-sorter {
  vertical-align: top;
}
.ant-table-wrapper .ant-table-header-column .ant-table-column-sorter .on {
    color: #5068C2 !important;
}
.ant-table-small {
  border: none;
}
.ant-table-small .ant-table-body {
    margin: 0;
}
.ant-table-small .ant-table-body table {
      border-collapse: separate;
      border-radius: 4px;
}
.ant-table-small .ant-table-body table th {
        font: normal normal 500 12px/33px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        color: #1F2437;
        background: #EFF0F3 0% 0% no-repeat padding-box !important;
        height: 36px !important;
        padding: 0px 10px !important;
        border: none !important;
}
@media screen and (max-width: 767px) {
.ant-table-small .ant-table-body table th {
            font: normal normal 500 11px/33px "Noto Sans JP", sans-serif;
}
}
.ant-table-small .ant-table-body table td {
        font: normal normal 300 14px/33px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        padding: 0px 10px !important;
}
@media screen and (max-width: 767px) {
.ant-table-small .ant-table-body table td {
            font: normal normal 500 11px/33px "Noto Sans JP", sans-serif;
}
}
.ant-table-small .ant-table-body table tr.index_stripe_3gZGB {
        background: #FAFBFC 0% 0% no-repeat padding-box;
}
